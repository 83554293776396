<!--
  Component containing merge options for duplicates.

  Should give the user more control on how merge strategies are performed.
-->
<template>
  <div>
    <b-card
      title=""
      class="mb-2 edit-merge-options"
      ref="merge-options"
      id="merge-options"
      hide-footer
    >
      <b-row>
        <b-col>

          <JobProgressInformation
            v-if="local_item"
            :item="local_item"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h5 class="mb-4">{{ $t('DUPLICATE.MERGE_MEMBER_OPTION') }}</h5>
          <p style="font-style: italic;">{{ $t('DUPLICATE.MERGE_MEMBERS_INFORMATION')  }}</p>
          <div class="radio-list">

            <label class="radio radio-outline radio-success mr-5">
              <input
                type="radio"
                v-model="selected_merge_member_option"
                value="KEEP_NEWEST_CREATED"
                name="selected_merge_member_option"
                checked="checked"
              />
              <span class="mr-2"></span>{{ $t('DUPLICATE.MERGE_MEMBER_OPTIONS.KEEP_NEWEST_CREATED') }}
            </label>

            <label class="radio radio-outline radio-success mr-5">
              <input
                type="radio"
                v-model="selected_merge_member_option"
                value="KEEP_OLDEST_CREATED"
                name="selected_merge_member_option"
                checked="checked"
              />
              <span class="mr-2"></span>{{ $t('DUPLICATE.MERGE_MEMBER_OPTIONS.KEEP_OLDEST_CREATED') }}
            </label>

          </div>
        </b-col>
      </b-row>



      <b-row>
        <b-col>
          <h5 class="mb-4">{{ $t('DUPLICATE.MERGE_MEMBERSHOP_OPTION') }}</h5>
          <p style="font-style: italic;">{{ $t('DUPLICATE.MERGE_MEMBERSHIP_INFORMATION')  }}</p>
          <div class="radio-list">

            <label class="radio radio-outline radio-success mr-5">
              <input
                type="radio"
                v-model="selected_merge_membership_option"
                value="KEEP_ALL"
                name="selected_merge_membership_option"
                checked="checked"
              />
              <span class="mr-2"></span>{{ $t('DUPLICATE.MERGE_MEMBERSHIP_OPTIONS.KEEP_ALL') }}
            </label>

            <label class="radio radio-outline radio-success mr-5">
              <input
                type="radio"
                v-model="selected_merge_membership_option"
                value="REMOVE_EXACT_DUPLICATES"
                name="selected_merge_membership_option"
                checked="checked"
              />
              <span class="mr-2"></span>{{ $t('DUPLICATE.MERGE_MEMBERSHIP_OPTIONS.REMOVE_EXACT_DUPLICATES') }}
            </label>

          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h5 class="mb-4">{{ $t('DUPLICATE.EXCEPTIONS') }}</h5>

          <p>{{ $t('DUPLICATE.CONFLICT_INFORMATION') }}</p>

          <p><li>{{ $t('DUPLICATE.CONFLICT_INFORMATION1') }}</li></p>
          <p style="font-style: italic;">Exempel: Berit Nilsson finns som 2 medlemsposter, ena har medlemsnummer 100 och den andra har medlemsnummer 203. Medlem 100 har en aktiv förnyelse. Medlem 203 har också en aktiv förnyelse. Den slutgiltiga medlemmen kan inte slås samman då det skulle innebära 2 aktiva förnyelser.</p>

          <p><li>{{ $t('DUPLICATE.CONFLICT_INFORMATION2') }}</li></p>
          <p style="font-style: italic;">Exempel: Anders Svensson finns som 2 medlemsposter, ena har medlemsnummer 122 och den andra har medlemsnummer 313. Medlem 122 är huvudmedlem och har inga familjekopplingar. Medlem 313 är icke huvudmedlem i ett familjemedlemskap med Lisa Andersson(314). Körningen kan inte besluta om vem som skall vara huvudmedlem och därför exkluderas ur körningen.</p>

        </b-col>
      </b-row>



    </b-card>
  </div>
</template>

<style lang="scss" scoped>

/* add some styles here */

</style>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// use axios for GET, PUT, POST, DELETE to API
//import axios from 'axios';
import { mapGetters } from 'vuex';

// date formatter
// import dayjs from 'dayjs';

export default {
  name: 'DuplicatesJobEditor',
  mixins: [ toasts ],
  components: {},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  emits: ['updated','created'],
  computed: {

    // check that the form is valid
    is_valid() {
      return true
    },

    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'isTHS']),

    // calculated, changed when value.example_id is updated
    creating() {
      return isNaN(parseInt(this.value.example_id+''));
    }
  },
  // when component is mounted/rendered for the first time
  mounted() {

  },
  // local data to the component can be stored here
  data() {
    return {
      local_item: {},

      selected_merge_member_option: null,
      selected_merge_membership_option: null,
    };
  },

  watch: {

    // when values has changed in the form
    value: {
      deep: true, // check properties of the form, not just the form reference (value.name for example)
      handler(val) {
        // emit the value updated, will emit the form in its entirety
        // example: val = { example_id: 1, name: 'test', name2: 'test2' }
        this.$emit('update:example', val);
      }
    },

  },
  methods: {
    // add any methods we need

    on_submit() {
      // submit was clicked
      this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EXAMPLES.ON_SUBMIT'));
    }
  }
};
</script>
