<template>
  <b-card>
    <Confirm title="Radera medlem" message="Vill du radera medlem?" ref="confirmDelete" :type="'success'"
      @confirm="onConfirmDeleteMember" />

    <Confirm title="Sammanfoga dubbletter" :message="mergeMessage" ref="confirmMergeDuplicates" :type="'success'"
      @confirm="onConfirmMergeDuplicates" />

    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            first-number
            last-number
          />
        </div>
      </b-col>
    </b-row>

    <b-table
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      head-variant="light"
      :fields="duplicate_headers"
      :items="duplicates_summary_rows"
      :per-page="per_page"
      :current-page="current_page"
      :filter="search"
    >
      <template #cell(firstname)="{ item }">
        <td>
          {{ item.firstname }} {{ item.lastname }}
        </td>
      </template>
      <template #cell(show_details)="row">
        <b-button variant="link" size="sm" @click="row.toggleDetails" class="mr-2">
          <b-icon v-if="row.detailsShowing" icon="chevron-up" />
          <b-icon v-else icon="chevron-down" />
        </b-button>
      </template>
      <template #row-details="{ item }">
        <DuplicatesSubMembersTable
          :showConfirm="showConfirm"
          :subMembers="item.members"
          :duplicateSummaryItem="item"
          @onDeleteSubMember="onDeleteSubMemberClicked"
        />
      </template>
    </b-table>

  </b-card>
</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';
import DuplicatesSubMembersTable from '@/view/pages/ml/duplicates/DuplicatesSubMembersTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'DuplicatesTable',
  components: {
    DuplicatesSubMembersTable,
    Confirm
  },
  emits: ['mergeDuplicateMembers'],
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods']),
  },
  mounted() {
    this.loadDuplicates();
  },
  data() {
    return {
      showConfirm: true,
      duplicate_headers: [
      {
          label: this.$t('MEMBER.PUBLIC_ID'),
          sortable: true,
          key: 'public_id'
        },
        {
          label: this.$t('MEMBER.EMAIL'),
          sortable: true,
          key: 'email'
        },
        {
          label: this.$t('MEMBER.FIRSTNAME'),
          key: 'firstname'
        },
        { label: this.$t('PAGES.DUPLICATES.NUM_DUPLICATES'), key: 'amount' },
        { label: '', key: 'show_details', tdClass: 'text-right' }
      ],
      duplicates_summary_rows: [],
      headerProps: {
        sortByText: 'Sortera'
      },
      activeExpanded: [],
      closedExpanded: [],
      singleExpand: false,
      rowsPerPage: [100, 200, 500, -1],
      per_page: 100,
      current_page: 1,
      total_rows: 0,
      search: null,
      summaryMemberId: null,
      subMemberId: null,
      members: [],
      mergeMessage: ''
    };
  },
  watch: {
    duplicate_headers(new_val) {
      this.total_rows = new_val.length
    },
  },
  methods: {
    showConfirmMergeDuplicates() {
      this.members = [];

      let count_unique = 0;
      let count_to_remove = 0;

      for (const ds of this.duplicates_summary_rows) {
        const to_merge = [];
        count_unique++;

        for (const m of ds.members) {
          count_to_remove++;
          to_merge.push({
            member_id: m.member_id
          });
        }

        this.members.push(to_merge);

        count_to_remove--;
      }

      this.mergeMessage = 'Detta kommer bli resultatet av körningen: <br/><br/>Totalt existerar nu ' + (count_unique + count_to_remove) + ' medlemmar som har dubbletter. När körningen är klar kommer av dessa medlemmar endast ' + count_unique + ' medlemmar finnas kvar. Resterande ' + count_to_remove + ' kommer att få sina medlemskap sammanfogade med respektive medlem. <br/><br/>Vill du fortsätta med denna åtgärd som EJ går att ångra?';

      this.$refs['confirmMergeDuplicates'].show();

    },

    onConfirmMergeDuplicates() {
      this.$emit('mergeDuplicateMembers', this.members);
    },

    createMergeJob() {

    },
    reload() {
      this.loadDuplicates();
    },
    onConfirmDeleteMember() {
      this.deleteMember(this.summaryMemberId, this.subMemberId);
    },

    deleteMember(summaryMemberId, subMemberId) {
      axios
        .delete(`/member/${subMemberId}`)
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), 'Medlemmen togs bort');

            const item = this.duplicates_summary_rows.find(item => item.member_id === summaryMemberId);

            item.members = item.members.filter(item => item.member_id !== subMemberId);
            item.amount = item.amount - 1;

            if (item.amount === 1) {
              this.duplicates_summary_rows = this.duplicates_summary_rows.filter(item => item.member_id !== summaryMemberId);
            }
          }
        })
        .catch(err => {
          console.error(err);

          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort medlemmen');
        });
    },

    loadDuplicates() {
      const loader = this.$loading.show();

      axios
        .get(`/member/duplicates`)
        .then(res => {
          this.duplicates_summary_rows = res.data;
          loader && loader.hide();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta dubletter');
          loader && loader.hide();
        });
    },

    onDeleteSubMemberClicked(summaryMemberId, subMemberId) {
      if (this.showConfirm) {
        this.summaryMemberId = summaryMemberId;
        this.subMemberId = subMemberId;
        this.$refs['confirmDelete'].show();
      }
      else {
        this.deleteMember(summaryMemberId, subMemberId);
      }
    },

    scrollToEditDuplicate() {
      var that = this;
      setTimeout(function () {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },

  }
};
</script>

<style scoped lang="scss">


:deep td {
  vertical-align: middle !important;
}
</style>
