<template>
  <div>

    <b-modal ref="popup" hide-footer >
      <h5>{{ $t('JOB.JOB_RUNNING') }}</h5>
      <b-progress style="background-color: #e1e8ff;" v-if="max > 0" :max="max" height="2rem">
        <b-progress-bar :value="value">
          <span>{{ $t('JOB.PROGRESS') }} <strong>{{ value }} / {{ max }}</strong></span>
        </b-progress-bar>
      </b-progress>
    </b-modal>
  </div>

</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'JobProgressInformation',
  components: {
    Confirm
  },
  mixins: [ toasts ],
  props: ['job_id'],
  emits: ['finished'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'companies']),
  },

  beforeDestroy() {
    this.stop_poll_job();
  },

  async mounted() {

    if (this.job_id) {
      this.job = await this.get_job(this.job_id);
      this.setup_job();

      this.start_poll_job();
    }
  },
  data() {
    return {
      max: 0,
      value: 0,
      poll_id: null,
      job: null
    };
  },
  watch: {
    job_id: {
      deep: true,
      async handler(val) {

        if (val) {
          this.job = await this.get_job(val);
          this.setup_job();

          this.start_poll_job();
        }

      }
    },

  },
  methods: {

    show() {
      this.$refs['popup'].show();
    },

    hide() {
      this.$refs['popup'].hide();
    },

    start_poll_job() {
      if (!this.job) {
        console.error('no job to poll');
        return;
      }

      if (this.poll_id) {
        console.error('already polling');
        return;
      }

      this.poll_id = setInterval(this.poll_job, 2000);
    },

    async setup_job() {

      if (!this.job) {
        return;
      }

      this.max = this.job.num_records;
      this.value = this.job.num_processed;

      if (this.job.status === 'FINISHED') {
        this.$emit('finished', this.job);
        this.stop_poll_job();
      }

    },

    async poll_job() {
      if (!this.job) {
        return;
      }

      const job = await this.get_job(this.job.id);

      if (job) {
        this.job = job;
        this.setup_job();
      }
      else {
        console.error('unable to poll job, stopping');
        this.stop_poll_job();
      }
    },

    stop_poll_job() {
      if (!this.poll_id) {
        return;
      }

      clearInterval(this.poll_id);
      this.poll_id = null;
    },

    async get_job(job_id) {
      try {
        const res = await axios.get(`/job/poll/${job_id}`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('get_job error', err);

      }

      return null;
    }

  }
};
</script>
