<template>
  <div id="page-duplicates">
    <h5 class="m-4">{{ $t('AUTH.MENU.DUPLICATES') }}</h5>


    <b-card>
      <v-tabs background-color="white">
        <v-tab>{{ $t('DUPLICATE.TAB_HEADER') }}</v-tab>
        <v-tab>{{ $t('COMMON.SETTINGS') }}</v-tab>

        <v-tab-item class="py-8">
          
          <!-- When ready, add a button to create a new merge job from the modal DuplicatesJobEditorModal -->
          <JobTable 
            v-if="running_job"
            type="MERGE"
            class="mb-8"
          />

          <DuplicatesMergeOptions 
            ref="duplicatesMergeOptions"
          />

          <DuplicatesTable 
            ref="duplicatesTable"
            @mergeDuplicateMembers="mergeDuplicateMembers"
          />
        </v-tab-item>
        <v-tab-item class="py-8">
          <DuplicatesSettings
            @onSettingsUpdated="onSettingsUpdated"
           /> 
        </v-tab-item>
    </v-tabs>
    </b-card>

    
  </div>
</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';
import DuplicatesJobEditorModal from '@/view/pages/ml/duplicates/DuplicatesJobEditorModal.vue';
import DuplicatesTable from '@/view/pages/ml/duplicates/DuplicatesTable.vue';
import DuplicatesSettings from '@/view/pages/ml/duplicates/DuplicatesSettings.vue';
import JobTable from '@/view/pages/ml/jobs/JobTable.vue';
import JobProgressInformation from '@/view/pages/ml/jobs/JobProgressInformation.vue';


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'duplicates',
  components: {
    DuplicatesJobEditorModal,
    DuplicatesTable,
    DuplicatesSettings,
    JobTable,
    JobProgressInformation
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'reg_only']),
  },
  mounted() {
    const loader = this.$loading.show();

    loader && loader.hide();
  },
  data() {
    return {
      running_job: null
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {

      }
    },
  },
  methods: {
    mergeDuplicateMembers(members) {

    },
    onSettingsUpdated(cols) {
      this.$refs['duplicatesTable'].reload();
    },

    scrollToEditDuplicate() {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },
    
  }
};
</script>
